<template>
  <v-card outlined>
    <v-card-text v-if="!!artist.bio && artist.bio.length > 10" v-html="artist.bio"></v-card-text>
    <v-card-title v-else>No details available yet</v-card-title>
  </v-card>
</template>

<script>

export default {
  props: {
    artist: Object
  },
  components: {
  },
  directives: {
  },
  data () {
    return {
    }
  }
}
</script>
<style scoped>
</style>
